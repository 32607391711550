.app {
    width: 100%;
    text-align: center;
    overflow-x: hidden;
    color: white;
    font-family: "Courier New", Courier, monospace;

    .header {
        padding-top: 2%;
        padding-bottom: 2%;

        #logo {
            width: 25%;
        }
    }

    .navigation {
        padding-bottom: 2%;
    }

    .content {
        padding-bottom: 2%;

        .home {
            width: 30%;
            display: inline-block;

            .playlist {
                position: relative;
                padding-bottom: 56.25%;

                iframe {
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    position: absolute;
                }
            }
        }

        .about {
            width: 30%;
            display: inline-block;

            #shot {
                width: 100%;
                padding-bottom: 5%;
            }

            #spacer {
                border-right: 2px solid #fff;
            }

            #blurb {
                margin-left: 2%;
                margin-right: 2%;
                font-size: 16pt;
            }
        }

        .contact {
            width: 20%;
            display: inline-block;

            .form-item {
                padding-bottom: 3%;
            }
        }
    }

    .footer {
        padding-bottom: 2%;

        .socials {
            width: 200px;
            display: inline-block;
            background: rgba(185, 148, 197, .6);
            border-radius: 7px;

            .icon {
                margin: 5px;
            }
        }
    }

    #background {
        z-index: -1;
        position: fixed;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
    }

    #btn {
        color: white;
        text-decoration: none;
        font-size: 18pt;
    }
    
    #btn:hover {
        color: #B994C5;
        text-decoration: underline;
    }
}

@media (max-width: 900px) {
    .app {
        .header {
            padding-top: 2%;
            padding-bottom: 2%;

            #logo {
                width: 90%;
                padding-left: 2%;
                padding-right: 2%;
            }
        }

        .navigation {
            padding-bottom: 2%;
            padding-left: 30%;  // hacky way of getting nav items on 1 column for mobile
            padding-right: 30%; // hacky way of getting nav items on 1 column for mobile
        }

        .content {
            padding-bottom: 2%;

            .home {
                width: 97%;
            }

            .about {
                width: 75%;
                padding-top: 3%;

                #blurb {
                    padding-top: 3%;
                }
            }

            .contact {
                width: 60%;
            }
        }

        .footer {
            padding-bottom: 10%;
        }
    }
}